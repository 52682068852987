import select2 from "select2";
window.select2 = select2();

$(document).on("turbo:load", function () {
  $("select.select2").select2({
    language: {
      noResults: function () {
        return I18n.t("general.no_result_found");
      },
    },
  });

  // From: https://discuss.hotwired.dev/t/how-to-use-stimulus-inside-bootstraps-popovers/177
  // Allows to process change event on select using select2 in a turbo context
  $("select.select2").on("select2:select", function (e) {
    let event = new Event("change", { bubbles: true });
    e.target.dispatchEvent(event);
  });
});
