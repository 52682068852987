import ConfettiGenerator from "confetti-js";

var confettiSettings = {
  target: "confetti-holder",
  max: "200",
  size: "1",
  animate: true,
  props: [
    "circle",
    "square",
    "triangle",
    "line",
    {
      type: "svg",
      src: "/carbo.svg",
      weight: 0.2,
    },
  ],
  colors: [
    [165, 104, 246],
    [230, 61, 135],
    [0, 199, 228],
    [253, 214, 126],
  ],
  clock: "20",
  rotate: true,
  start_from_edge: false,
  respawn: false,
};

$(document).on("turbo:load", function () {
  var canvas = document.getElementById("confetti-holder");
  if (canvas) {
    var confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
  }
});
