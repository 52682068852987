// Google Maps Place Autocomplete
// https://developers.google.com/maps/documentation/javascript/place-autocomplete?hl=fr

// Enabling behavior an input:
// <input type="text" data-gmaps-place-autocomplete=true>

// Specifying a type (or types) for places to look for:
// <input type="text" data-gmaps-place-autocomplete=true data-gmaps-place-autocomplete-type=TYPE>
// (defaults to "address", comma-separated list allowed, type list available here https://developers.google.com/maps/documentation/javascript/supported_types?hl=fr)

// Mapping place components to other inputs based on their types:
// <input type="text" data-gmaps-place-autocomplete=true gmaps-place-autocomplete-components="survey_office_">
// <input type="hidden" id="survey_office_country"
// (for an example see offices/_new)

// Mapping place latitude and longitude to other inputs:
// <input type="text" data-gmaps-place-autocomplete=true gmaps-place-autocomplete-latlng="survey_trip_latitude,survey_trip_longitude">
// WARNING will not work with multiple instances on the same page since this implementation is based on inputs IDs

$(document).on("turbo:load", function () {
  const inputs = document.querySelectorAll("[data-gmaps-place-autocomplete]");
  Array.from(inputs).forEach((input) => {
    const autocomplete = addPlaceAutocompleteOnInput(input);
    submitFormOnInputPlaceChanged(autocomplete, input);
  });
});

function addPlaceAutocompleteOnInput(input) {
  const types = input.dataset["gmapsPlaceAutocompleteTypes"]?.split(",") || ["address"];
  const fields = ["geometry.location"];
  if (input.dataset["gmapsPlaceAutocompleteComponents"]) {
    fields.push("address_components");
  }
  return new google.maps.places.Autocomplete(input, { types, fields });
}

function submitFormOnInputPlaceChanged(autocomplete, input) {
  google.maps.event.addListener(autocomplete, "place_changed", function () {
    const typeInputsSelectorsPrefix = input.dataset["gmapsPlaceAutocompleteComponents"]
    if (typeInputsSelectorsPrefix) {
      const place = this.getPlace();
      mapPlaceComponentTypesToInputs(place, typeInputsSelectorsPrefix);
    }

    const latlngInputSelectors = input.dataset["gmapPlaceAutocompleteLatlng"]?.split(",");
    if (latlngInputSelectors) {
        const place = this.getPlace();
        mapPlaceLocationToInputs(place, latlngInputSelectors);
    }

    Rails.fire(input.form, "submit");
  });
}

function mapPlaceComponentTypesToInputs(place, typeInputsSelectorsPrefix) {
  place.address_components.forEach(({ long_name, types }) => {
    types.forEach((type) => {
      const typeElement = document.getElementById(`${typeInputsSelectorsPrefix}${type}`)
      if (typeElement) {
        typeElement.value = long_name;
      }
    })
  })
}

function mapPlaceLocationToInputs(place, [latInputSelector, lngInputSelector]) {
  const latInput = document.getElementById(latInputSelector);
  const longInput = document.getElementById(lngInputSelector);
  if (latInput && longInput) {
    latInput.value = place.geometry.location.lat()
    longInput.value = place.geometry.location.lng()
  }
}
