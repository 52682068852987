import { I18n } from "i18n-js";
import translations from "~/build/translations.json";

const i18n = new I18n(translations);
i18n.defaultLocale = "fr";

$(document).on("turbo:load", function () {
  var locale = $("body").data("locale");
  if (locale) {
    i18n.locale = locale;
  }
});

window.I18n = i18n;
